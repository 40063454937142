import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p className="header4">With a background in graphic design and a history of tinkering with the web, I'm interested in how code connects design to engineering. Currently I'm a Systems Designer at GitHub working on <a href="https://primer.style/">Primer</a>. Outside of work I co-organize <a href="https://www.meetup.com/Ladies-That-UX-Boston/" target="_blank" rel="noopener noreferrer">Ladies that UX Boston</a>, a meetup group empowering women who work in design and technology.</p>
    <span className="header4">Here's what I'm <a className="linkArrow" href="https://fir-pet-9c5.notion.site/54abd5decac844bb870bdce4ffcd8377?v=b6ace1e3398840e69c1e0e34c2f18820" target="_blank" rel="noopener noreferrer">currently up to</a></span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      